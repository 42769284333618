  // Vehicle Type
  export const VehicleType = [
    {
      option: "20 SEATER BUS",
      optionvalue: "20SEATERBUS",
    },
    {
      option: "45 SEATER BUS",
      optionvalue: "45SEATERBUS",
    },
    {
      option: "ACCORD",
      optionvalue: "ACCORD",
    },
    {
      option: "AMAZE",
      optionvalue: "AMAZE",
    },
    {
      option: "AUDI",
      optionvalue: "AUDI",
    },
    {
      option: "AUDI A6",
      optionvalue: "AUDIA6",
    },
    {
      option: "BALENO",
      optionvalue: "BALENO",
    },
    {
      option: "BENZ E CLASS",
      optionvalue: "BENZECLASS",
    },
    {
      option: "BENZ S CLASS",
      optionvalue: "BENZSCLASS",
    },
    {
      option: "BIKE",
      optionvalue: "BIKE",
    },
    {
      option: "BMW 5 SERIES",
      optionvalue: "BMW5SERIES",
    },
    {
      option: "BMW 7 SERIES",
      optionvalue: "BMW7SERIES",
    },
    {
      option: "BUS",
      optionvalue: "BUS",
    },
    {
      option: "BUS - 20 SEATER",
      optionvalue: "BUS-20SEATER",
    },
    {
      option: "CAMRY A/C",
      optionvalue: "CAMRYA/C",
    },
    {
      option: "COROLLA A/C",
      optionvalue: "COROLLAA/C",
    },
    {
      option: "COROLLA ALTIS A/C",
      optionvalue: "COROLLAALTISA/C",
    },
    {
      option: "CRYSTA A/C",
      optionvalue: "CRYSTAA/C",
    },
    {
      option: "DELUXE VAN",
      optionvalue: "DELUXEVAN",
    },
    {
      option: "DZIRE A/C",
      optionvalue: "DZIREA/C",
    },
    {
      option: "ERTIGA",
      optionvalue: "ERTIGA",
    },
    {
      option: "ETIOS A/C",
      optionvalue: "ETIOSA/C",
    },
    {
      option: "FIESTA A/C",
      optionvalue: "FIESTAA/C",
    },
    {
      option: "FIGO A/C",
      optionvalue: "FIGOA/C",
    },
    {
      option: "FORD ENDEAVOR A/C",
      optionvalue: "FORDENDEAVORA/C",
    },
    {
      option: "FORD IKON A/C",
      optionvalue: "FORDIKONA/C",
    },
    {
      option: "FORTUNER",
      optionvalue: "FORTUNER",
    },
    {
      option: "HONDA CITY",
      optionvalue: "HONDACITY",
    },
    {
      option: "HONDA CITY A/C",
      optionvalue: "HONDACITYA/C",
    },
    {
      option: "HYUNDAI VERNA",
      optionvalue: "HYUNDAIVERNA",
    },
    {
      option: "INDICA A/C",
      optionvalue: "INDICAA/C",
    },
    {
      option: "INDICA NON A/C",
      optionvalue: "INDICANONA/C",
    },
    {
      option: "INDIGO A/C",
      optionvalue: "INDIGOA/C",
    },
    {
      option: "INNOVA A/C",
      optionvalue: "INNOVAA/C",
    },
    {
      option: "ISUZU",
      optionvalue: "ISUZU",
    },
    {
      option: "LOGAN",
      optionvalue: "LOGAN",
    },
    {
      option: "MAHINDRA VAN",
      optionvalue: "MAHINDRAVAN",
    },
    {
      option: "MERC VIANO A/C",
      optionvalue: "MERCVIANOA/C",
    },
    {
      option: "MINI BUS",
      optionvalue: "MINIBUS",
    },
    {
      option: "QUALIS",
      optionvalue: "QUALIS",
    },
    {
      option: "RITZ",
      optionvalue: "RITZ",
    },
    {
      option: "ROLLS ROYCE",
      optionvalue: "ROLLS-ROYCE",
    },
    {
      option: "SADAN A/C",
      optionvalue: "SADANA/C",
    },
    {
      option: "SUMO",
      optionvalue: "SUMO",
    },
    {
      option: "SUNNY Car",
      optionvalue: "SUNNY-car",
    },
    {
      option: "SWARAJ MAZDA",
      optionvalue: "SWARAJ-MAZDA",
    },
    {
      option: 'SWARAJ MAZDA NON A/C',
      optionvalue: "SWARAJ-MAZDA-NON-A/C",
    },
    {
      option: "TATA ACE",
      optionvalue: "TATA-ACE",
    },
    {
      option: "TATA ARIA",
      optionvalue: "TATA-ARIA",
    },
    {
      option: "TAVERA AC",
      optionvalue: "TAVERA-AC",
    },
    {
      option: "TAVERA NON A/C",
      optionvalue: "TAVERA-NON-A/C",
    },
    {
      option: "TEMPO 12 SEATER",
      optionvalue: "TEMPO-12-SEATER",
    },
    {
      option: "TEMPO 14 SEATER",
      optionvalue: "TEMPO-14-SEATER",
    },
    {
      option: "TEMPO 20 SEATER",
      optionvalue: "TEMPO-20-SEATER",
    },
    {
      option: "TEMPO A/C",
      optionvalue: "TEMPO-A/C",
    },
    {
      option: "TOURISTER VAN",
      optionvalue: "TOURISTER-VAN",
    },
    {
      option: "TOYOTO COMMUTER",
      optionvalue: "TOYOT-COMMUTER",
    },
    {
      option: "TOYOTO FORTUNER",
      optionvalue: "TOYOTO-FORTUNER",
    },
    {
      option: "TRAVELLER",
      optionvalue: "TRAVELLER",
    },
    {
      option: "TRAVELLER AC",
      optionvalue: "TRAVELLER-AC",
    },
    {
      option: "TVS STAR SPORTTRAVELLER AC",
      optionvalue: "TVS-STAR-SPORT",
    },
    {
      option: "TWO WHEELER",
      optionvalue: "TWO-WHEELER",
    },
    {
      option: "VOLVO - 28 SHEET BUS",
      optionvalue: "VOLVO-28-SHEET-BUS",
    },
    {
      option: "VOLVO - 40 SHEET BUS",
      optionvalue: "VOLVO-40-SHEET-BUS",
    },
    {
      option: "VOLVO - BUS",
      optionvalue: "VOLVO-BUS",
    },
    {
      option: "XCENT",
      optionvalue: "XCENT",
    },
    {
      option: "XYLO A/C",
      optionvalue: "XYLO-A/C",
    },
  ];

    // Duty
export const Duty = [
    {
      option: "Local",
      optionvalue: "local",
    },
    {
      option: "ONE WAY",
      optionvalue: "ONEWAY",
    },
    {
      option: "Outstation",
      optionvalue: "outstation",
    },
    {
      option: "Transfer",
      optionvalue: "transfer",
    },
  ];