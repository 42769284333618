export const TaxType = [
    {
        Option: "STAXEH",
        optionvalue: "STAXEH",
    },
    {
        Option: "STAXSB",
        optionvalue: "STAXSB",
    },
    {
        Option: "STAXSBKK",
        optionvalue: "STAXSBKK",
    },
    {
        Option: "STAX",
        optionvalue: "STAX",
    },
    {
        Option: "GST5",
        optionvalue: "GST5",
    },
    {
        Option: "GST12",
        optionvalue: "GST12",
    },
]