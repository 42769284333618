import React from 'react';
import "./EtripSheetImages.css"

const EtripSheetImages = () => {
  return (
    <>
      <div>
        <p>Images</p>
        <div className='EtripSheetImages-card'>

        </div>
      </div>
    </>
  )
}
export default EtripSheetImages;