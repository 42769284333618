// Status
export const Status = [
  {
    Option: "Opened",
    optionvalue: "opened",
  },
  {
    Option: "Closed",
    optionvalue: "Closed",
  },
  {
    Option: "Temporary Closed",
    optionvalue: "Temporary Closed",
  },
  {
    Option: "Cancelled",
    optionvalue: "cancelled",
  },
  {
    Option: "Billed",
    optionvalue: "Billed",
  },

  // {
  //   Option: "Transfer_Billed",
  //   optionvalue: "Transfer_Billed"
  // },
  // {
  //   Option: "Transfer_Closed",
  //   optionvalue: "Transfer_Closed"
  // },
  // {
  //   Option: "Covering_Billed",
  //   optionvalue: "Covering_Billed"
  // },
  // {
  //   Option: "Covering_Closed",
  //   optionvalue: "Covering_Closed"
  // }
];

export const GroupTypes = [
  {
    Option: "Luxzury",
    // optionvalue: "a/c",
  },
  {
    Option: "Normal",
    // optionvalue: "non_a/c",
  },
  {
    Option: "Premium",
    // optionvalue: "a/c",
  },
  {
    Option: "Non-Premium",
    // optionvalue: "non_a/c",
  },
];

// Apps

export const Apps = [
  {
    Option: "Waiting",
    optionvalue: "waiting",
  },
  {
    Option: "Accept",
    optionvalue: "accept",
  },
  {
    Option: "On_Going",
    optionvalue: "ongoing",
  },
  {
    Option: "Closed",
    optionvalue: "closed",
  },
  // {
  //   Option: "Be_Closed",
  //   optionvalue: "beclosed",
  // },
];

// Hire Types

export const HireTypes = [
  {
    option: "Attached Vehicle",
    optionvalue: "attachedvehicle",
  },
  {
    option: "Out Side Travels",
    optionvalue: "outsidetravels",
  },
  {
    option: "Own  Vehicle",
    optionvalue: "ownvehicle",
  },
];
// Department
export const Department = [
  {
    option: "All Station",
    optionvalue: "allstation",
  },
  {
    option: "Ahmedabad",
    optionvalue: "ahmedabad",
  },
  {
    option: "Bangalore",
    optionvalue: "bangalore",
  },
  {
    option: "Chennai",
    optionvalue: "chennai",
  },
  {
    option: "Coimbatore",
    optionvalue: "coimbatore",
  },
  {
    option: "Delhi",
    optionvalue: "delhi",
  },
  {
    option: "ERODE",
    optionvalue: "ERODE",
  },
  {
    option: "GOA",
    optionvalue: "goa",
  },
  {
    option: "Hyderabad",
    optionvalue: "hyderabad",
  },
  {
    option: "INDORE",
    optionvalue: "INDORE",
  },
  {
    option: "Jaipur",
    optionvalue: "jaipur",
  },
  {
    option: "Kerala",
    optionvalue: "kerala",
  },
  {
    option: "Kolkata",
    optionvalue: "kolkata",
  },
  {
    option: "Lucknow",
    optionvalue: "lucknow",
  },
  {
    option: "Madurai",
    optionvalue: "madurai",
  },
  {
    option: "Mangalore",
    optionvalue: "mangalore",
  },
  {
    option: "Mumbai",
    optionvalue: "mumbai",
  },
  {
    option: "Nagpur",
    optionvalue: "nagpur",
  },
  {
    option: "New One",
    optionvalue: "newone",
  },
  {
    option: "Pune",
    optionvalue: "pune",
  },
  {
    option: "Tiruchi",
    optionvalue: "tiruchi",
  },
  {
    option: "Vijayawada",
    optionvalue: "vijayawada",
  },
];
// Vehicle Rate
export const VehicleRate = [
  {
    option: "20 SEATER BUS",
    optionvalue: "20SEATERBUS",
  },
  {
    option: "45 SEATER BUS",
    optionvalue: "45SEATERBUS",
  },
  {
    option: "ACCORD",
    optionvalue: "ACCORD",
  },
  {
    option: "AMAZE",
    optionvalue: "AMAZE",
  },
  {
    option: "AUDI",
    optionvalue: "AUDI",
  },
  {
    option: "AUDI A6",
    optionvalue: "AUDIA6",
  },
  {
    option: "BALENO",
    optionvalue: "BALENO",
  },
  {
    option: "BENZ E CLASS",
    optionvalue: "BENZECLASS",
  },
  {
    option: "BENZ S CLASS",
    optionvalue: "BENZSCLASS",
  },
  {
    option: "BIKE",
    optionvalue: "BIKE",
  },
  {
    option: "BMW 5 SERIES",
    optionvalue: "BMW5SERIES",
  },
  {
    option: "BMW 7 SERIES",
    optionvalue: "BMW7SERIES",
  },
  {
    option: "BUS",
    optionvalue: "BUS",
  },
  {
    option: "BUS - 20 SEATER",
    optionvalue: "BUS-20SEATER",
  },
  {
    option: "CAMRY A/C",
    optionvalue: "CAMRYA/C",
  },
  {
    option: "COROLLA A/C",
    optionvalue: "COROLLAA/C",
  },
  {
    option: "COROLLA ALTIS A/C",
    optionvalue: "COROLLAALTISA/C",
  },
  {
    option: "CRYSTA A/C",
    optionvalue: "CRYSTAA/C",
  },
  {
    option: "DELUXE VAN",
    optionvalue: "DELUXEVAN",
  },
  {
    option: "DZIRE A/C",
    optionvalue: "DZIREA/C",
  },
  {
    option: "ERTIGA",
    optionvalue: "ERTIGA",
  },
  {
    option: "ETIOS A/C",
    optionvalue: "ETIOSA/C",
  },
  {
    option: "FIESTA A/C",
    optionvalue: "FIESTAA/C",
  },
  {
    option: "FIGO A/C",
    optionvalue: "FIGOA/C",
  },
  {
    option: "FORD ENDEAVOR A/C",
    optionvalue: "FORDENDEAVORA/C",
  },
  {
    option: "FORD IKON A/C",
    optionvalue: "FORDIKONA/C",
  },
  {
    option: "FORTUNER",
    optionvalue: "FORTUNER",
  },
  {
    option: "HONDA CITY",
    optionvalue: "HONDACITY",
  },
  {
    option: "HONDA CITY A/C",
    optionvalue: "HONDACITYA/C",
  },
  {
    option: "HYUNDAI VERNA",
    optionvalue: "HYUNDAIVERNA",
  },
  {
    option: "INDICA A/C",
    optionvalue: "INDICAA/C",
  },
  {
    option: "INDICA NON A/C",
    optionvalue: "INDICANONA/C",
  },
  {
    option: "INDIGO A/C",
    optionvalue: "INDIGOA/C",
  },
  {
    option: "INNOVA A/C",
    optionvalue: "INNOVAA/C",
  },
  {
    option: "ISUZU",
    optionvalue: "ISUZU",
  },
  {
    option: "LOGAN",
    optionvalue: "LOGAN",
  },
  {
    option: "MAHINDRA VAN",
    optionvalue: "MAHINDRAVAN",
  },
  {
    option: "MERC VIANO A/C",
    optionvalue: "MERCVIANOA/C",
  },
  {
    option: "MINI BUS",
    optionvalue: "MINIBUS",
  },
  {
    option: "QUALIS",
    optionvalue: "QUALIS",
  },
  {
    option: "RITZ",
    optionvalue: "RITZ",
  },
  {
    option: "ROLLS ROYCE",
    optionvalue: "ROLLS-ROYCE",
  },
  {
    option: "SADAN A/C",
    optionvalue: "SADANA/C",
  },
  {
    option: "SUMO",
    optionvalue: "SUMO",
  },
  {
    option: "SUNNY Car",
    optionvalue: "SUNNY-car",
  },
  {
    option: "SWARAJ MAZDA",
    optionvalue: "SWARAJ-MAZDA",
  },
  {
    option: 'SWARAJ MAZDA NON A/C',
    optionvalue: "SWARAJ-MAZDA-NON-A/C",
  },
  {
    option: "TATA ACE",
    optionvalue: "TATA-ACE",
  },
  {
    option: "TATA ARIA",
    optionvalue: "TATA-ARIA",
  },
  {
    option: "TAVERA AC",
    optionvalue: "TAVERA-AC",
  },
  {
    option: "TAVERA NON A/C",
    optionvalue: "TAVERA-NON-A/C",
  },
  {
    option: "TEMPO 12 SEATER",
    optionvalue: "TEMPO-12-SEATER",
  },
  {
    option: "TEMPO 14 SEATER",
    optionvalue: "TEMPO-14-SEATER",
  },
  {
    option: "TEMPO 20 SEATER",
    optionvalue: "TEMPO-20-SEATER",
  },
  {
    option: "TEMPO A/C",
    optionvalue: "TEMPO-A/C",
  },
  {
    option: "TOURISTER VAN",
    optionvalue: "TOURISTER-VAN",
  },
  {
    option: "TOYOTO COMMUTER",
    optionvalue: "TOYOT-COMMUTER",
  },
  {
    option: "TOYOTO FORTUNER",
    optionvalue: "TOYOTO-FORTUNER",
  },
  {
    option: "TRAVELLER",
    optionvalue: "TRAVELLER",
  },
  {
    option: "TRAVELLER AC",
    optionvalue: "TRAVELLER-AC",
  },
  {
    option: "TVS STAR SPORTTRAVELLER AC",
    optionvalue: "TVS-STAR-SPORT",
  },
  {
    option: "TWO WHEELER",
    optionvalue: "TWO-WHEELER",
  },
  {
    option: "VOLVO - 28 SHEET BUS",
    optionvalue: "VOLVO-28-SHEET-BUS",
  },
  {
    option: "VOLVO - 40 SHEET BUS",
    optionvalue: "VOLVO-40-SHEET-BUS",
  },
  {
    option: "VOLVO - BUS",
    optionvalue: "VOLVO-BUS",
  },
  {
    option: "XCENT",
    optionvalue: "XCENT",
  },
  {
    option: "XYLO A/C",
    optionvalue: "XYLO-A/C",
  },
];
// Select
export const Select = [
  {
    option: "Don't Allow",
    optionvalue: "dont-allow",
  },
  {
    option: "Allow Once",
    optionvalue: "allow-once",
  },
  {
    option: "Always Allow",
    optionvalue: "always-allow",
  },
]
// Document Type
export const DocumentType = [
  {
    option: "TripSheet",
    optionvalue: "tripsheet",
  },
  {
    option: "Parking",
    optionvalue: "parking",
  },
  {
    option: "Toll",
    optionvalue: "toll",
  },
  {
    option: "Permit",
    optionvalue: "permit",
  },
  // {
  //   option: "Sign",
  //   optionvalue: "sign",
  // },
]
// Email
export const Email = [
  {
    option: "Local",
    optionvalue: "local",
  },
  {
    option: "Server",
    optionvalue: "server",
  },
];
// Duty
// export const Duty = [
//   {
//     option: "Local",
//     optionvalue: "local",
//   },
//   {
//     option: "ONE WAY",
//     optionvalue: "ONEWAY",
//   },
//   {
//     option: "Outstation",
//     optionvalue: "Outstation",
//   },
//   {
//     option: "Transfer",
//     optionvalue: "transfer",
//   },
// ];

export const Duty = [
  {
    option: "Local",
    optionvalue: "local",
  },
  {
    option: "OneWay",
    optionvalue: "oneway",
  },
  {
    option: "Outstation",
    optionvalue: "Outstation",
  },
  {
    option: "Transfer",
    optionvalue: "transfer",
  },
];
// Pickup

// export const Pickup = [
//   {
//     option: "AIRPORT PICKUP",
//     optionvalue: "AIRPORT-PICKUP",
//   },
//   {
//     option: "AMBATTUR",
//     optionvalue: "AMBATTUR",
//   },
//   {
//     option: "CENTRAL",
//     optionvalue: "CENTRAL",
//   },
//   {
//     option: "CROWNE PLAZA",
//     optionvalue: "CROWNE-PLAZA",
//   },
//   {
//     option: "ETA NAVALLUR",
//     optionvalue: "ETA-NAVALLUR",
//   },
//   {
//     option: "GATEWAY HOTEL",
//     optionvalue: "GATEWAY-HOTEL",
//   },
//   {
//     option: "HCL AMBATTUR",
//     optionvalue: "HCL-AMBATTUR",
//   },
//   {
//     option: "HCL CAMPUS",
//     optionvalue: "HCL-CAMPUS",
//   },
//   {
//     option: "HILTON HOTEL",
//     optionvalue: "HILTON-HOTEL",
//   },
//   {
//     option: "HOLIDAY INN",
//     optionvalue: "HOLIDAY-INN",
//   },
//   {
//     option: "HOLIDAY-IN-EXPRESS",
//     optionvalue: "HOLIDAY-IN-EXPRESS",
//   },
//   {
//     option: "ITC-GRAND-CHOLA",
//     optionvalue: "ITC-GRAND-CHOLA",
//   },
//   {
//     option: "KOYAMBEDU",
//     optionvalue: "KOYAMBEDU",
//   },
//   {
//     option: "LEELA PALACE",
//     optionvalue: "LEELA-PALACE",
//   },
//   {
//     option: "MADURAI",
//     optionvalue: "MADURAI",
//   },
//   {
//     option: "MARRIOT HOTEL",
//     optionvalue: "MARRIOT-HOTEL",
//   },
//   {
//     option: "NOVOTAL",
//     optionvalue: "NOVOTAL",
//   },
//   {
//     option: "PANDI",
//     optionvalue: "PANDI",
//   },
//   {
//     option: "PARK HYATH HOTEL",
//     optionvalue: "PARK-HYATH-HOTEL",
//   },
//   {
//     option: "SADAN A/C",
//     optionvalue: "SADAN-A/C",
//   },
//   {
//     option: "TEYNAMPET",
//     optionvalue: "TEYNAMPET",
//   },
// ];

// States

export const states = [
  {
    state: "Andhra Pradesh",
    statevalue: "andhra_pradesh",
    districts: [
      "Anantapur",
      "Chittoor",
      "East Godavari",
      "Guntur",
      "Krishna",
      "Kurnool",
      "Nellore",
      "Prakasam",
      "Srikakulam",
      "Visakhapatnam",
      "Vizianagaram",
      "West Godavari",
      "YSR Kadapa",
    ],
  },
  {
    state: "Arunachal Pradesh",
    statevalue: "arunachal_pradesh",
    districts: [
      "Tawang",
      "West Kameng",
      "East Kameng",
      "Papum Pare",
      "Kurung Kumey",
      "Kra Daadi",
      "Lower Subansiri",
      "Upper Subansiri",
      "West Siang",
      "East Siang",
      "Siang",
      "Upper Siang",
      "Lower Siang",
      "Lower Dibang Valley",
      "Dibang Valley",
      "Anjaw",
      "Lohit",
      "Namsai",
      "Changlang",
      "Tirap",
      "Longding",
    ],
  },
  {
    state: "Assam",
    statevalue: "assam",
    districts: [
      "Baksa",
      "Barpeta",
      "Biswanath",
      "Bongaigaon",
      "Cachar",
      "Charaideo",
      "Chirang",
      "Darrang",
      "Dhemaji",
      "Dhubri",
      "Dibrugarh",
      "Goalpara",
      "Golaghat",
      "Hailakandi",
      "Hojai",
      "Jorhat",
      "Kamrup Metropolitan",
      "Kamrup",
      "Karbi Anglong",
      "Karimganj",
      "Kokrajhar",
      "Lakhimpur",
      "Majuli",
      "Morigaon",
      "Nagaon",
      "Nalbari",
      "Dima Hasao",
      "Sivasagar",
      "Sonitpur",
      "South Salmara-Mankachar",
      "Tinsukia",
      "Udalguri",
      "West Karbi Anglong",
    ],
  },
  {
    state: "Bihar",
    statevalue: "bihar",
    districts: [
      "Araria",
      "Arwal",
      "Aurangabad",
      "Banka",
      "Begusarai",
      "Bhagalpur",
      "Bhojpur",
      "Buxar",
      "Darbhanga",
      "East Champaran (Motihari)",
      "Gaya",
      "Gopalganj",
      "Jamui",
      "Jehanabad",
      "Kaimur (Bhabua)",
      "Katihar",
      "Khagaria",
      "Kishanganj",
      "Lakhisarai",
      "Madhepura",
      "Madhubani",
      "Munger (Monghyr)",
      "Muzaffarpur",
      "Nalanda",
      "Nawada",
      "Patna",
      "Purnia (Purnea)",
      "Rohtas",
      "Saharsa",
      "Samastipur",
      "Saran",
      "Sheikhpura",
      "Sheohar",
      "Sitamarhi",
      "Siwan",
      "Supaul",
      "Vaishali",
      "West Champaran",
    ],
  },
  {
    state: "Chandigarh (UT)",
    statevalue: "chandigarh_(UT)",
    districts: ["Chandigarh"],
  },
  {
    state: "Chhattisgarh",
    statevalue: "chhattisgarh",
    districts: [
      "Balod",
      "Baloda Bazar",
      "Balrampur",
      "Bastar",
      "Bemetara",
      "Bijapur",
      "Bilaspur",
      "Dantewada (South Bastar)",
      "Dhamtari",
      "Durg",
      "Gariyaband",
      "Janjgir-Champa",
      "Jashpur",
      "Kabirdham (Kawardha)",
      "Kanker (North Bastar)",
      "Kondagaon",
      "Korba",
      "Korea (Koriya)",
      "Mahasamund",
      "Mungeli",
      "Narayanpur",
      "Raigarh",
      "Raipur",
      "Rajnandgaon",
      "Sukma",
      "Surajpur  ",
      "Surguja",
    ],
  },
  {
    state: "Dadra and Nagar Haveli (UT)",
    statevalue: "dadra_and_nagar_haveli_(UT)",
    districts: ["Dadra & Nagar Haveli"],
  },
  {
    state: "Daman and Diu (UT)",
    statevalue: "daman_and_diu_(UT)",
    districts: ["Daman", "Diu"],
  },
  {
    state: "Delhi (NCT)",
    statevalue: "delhi_(NCT)",
    districts: [
      "Central Delhi",
      "East Delhi",
      "New Delhi",
      "North Delhi",
      "North East  Delhi",
      "North West  Delhi",
      "Shahdara",
      "South Delhi",
      "South East Delhi",
      "South West  Delhi",
      "West Delhi",
    ],
  },
  {
    state: "Goa",
    statevalue: "goa",
    districts: ["North Goa", "South Goa"],
  },
  {
    state: "Gujarat",
    districts: [
      "Ahmedabad",
      "Amreli",
      "Anand",
      "Aravalli",
      "Banaskantha (Palanpur)",
      "Bharuch",
      "Bhavnagar",
      "Botad",
      "Chhota Udepur",
      "Dahod",
      "Dangs (Ahwa)",
      "Devbhoomi Dwarka",
      "Gandhinagar",
      "Gir Somnath",
      "Jamnagar",
      "Junagadh",
      "Kachchh",
      "Kheda (Nadiad)",
      "Mahisagar",
      "Mehsana",
      "Morbi",
      "Narmada (Rajpipla)",
      "Navsari",
      "Panchmahal (Godhra)",
      "Patan",
      "Porbandar",
      "Rajkot",
      "Sabarkantha (Himmatnagar)",
      "Surat",
      "Surendranagar",
      "Tapi (Vyara)",
      "Vadodara",
      "Valsad",
    ],
  },
  {
    state: "Haryana",
    statevalue: "haryana",
    districts: [
      "Ambala",
      "Bhiwani",
      "Charkhi Dadri",
      "Faridabad",
      "Fatehabad",
      "Gurgaon",
      "Hisar",
      "Jhajjar",
      "Jind",
      "Kaithal",
      "Karnal",
      "Kurukshetra",
      "Mahendragarh",
      "Mewat",
      "Palwal",
      "Panchkula",
      "Panipat",
      "Rewari",
      "Rohtak",
      "Sirsa",
      "Sonipat",
      "Yamunanagar",
    ],
  },
  {
    state: "Himachal Pradesh",
    statevalue: "himachalpradesh",
    districts: [
      "Bilaspur",
      "Chamba",
      "Hamirpur",
      "Kangra",
      "Kinnaur",
      "Kullu",
      "Lahaul &amp; Spiti",
      "Mandi",
      "Shimla",
      "Sirmaur (Sirmour)",
      "Solan",
      "Una",
    ],
  },
  {
    state: "Jammu and Kashmir",
    statevalue: "jammu_and_kashmir",
    districts: [
      "Anantnag",
      "Bandipore",
      "Baramulla",
      "Budgam",
      "Doda",
      "Ganderbal",
      "Jammu",
      "Kargil",
      "Kathua",
      "Kishtwar",
      "Kulgam",
      "Kupwara",
      "Leh",
      "Poonch",
      "Pulwama",
      "Rajouri",
      "Ramban",
      "Reasi",
      "Samba",
      "Shopian",
      "Srinagar",
      "Udhampur",
    ],
  },
  {
    state: "Jharkhand",
    statevalue: "jharkhand",
    districts: [
      "Bokaro",
      "Chatra",
      "Deoghar",
      "Dhanbad",
      "Dumka",
      "East Singhbhum",
      "Garhwa",
      "Giridih",
      "Godda",
      "Gumla",
      "Hazaribag",
      "Jamtara",
      "Khunti",
      "Koderma",
      "Latehar",
      "Lohardaga",
      "Pakur",
      "Palamu",
      "Ramgarh",
      "Ranchi",
      "Sahibganj",
      "Seraikela-Kharsawan",
      "Simdega",
      "West Singhbhum",
    ],
  },
  {
    state: "Karnataka",
    statevalue: "karnataka",
    districts: [
      "Bagalkot",
      "Ballari (Bellary)",
      "Belagavi (Belgaum)",
      "Bengaluru (Bangalore) Rural",
      "Bengaluru (Bangalore) Urban",
      "Bidar",
      "Chamarajanagar",
      "Chikballapur",
      "Chikkamagaluru (Chikmagalur)",
      "Chitradurga",
      "Dakshina Kannada",
      "Davangere",
      "Dharwad",
      "Gadag",
      "Hassan",
      "Haveri",
      "Kalaburagi (Gulbarga)",
      "Kodagu",
      "Kolar",
      "Koppal",
      "Mandya",
      "Mysuru (Mysore)",
      "Raichur",
      "Ramanagara",
      "Shivamogga (Shimoga)",
      "Tumakuru (Tumkur)",
      "Udupi",
      "Uttara Kannada (Karwar)",
      "Vijayapura (Bijapur)",
      "Yadgir",
    ],
  },
  {
    state: "Kerala",
    statevalue: "kerala",
    districts: [
      "Alappuzha",
      "Ernakulam",
      "Idukki",
      "Kannur",
      "Kasaragod",
      "Kollam",
      "Kottayam",
      "Kozhikode",
      "Malappuram",
      "Palakkad",
      "Pathanamthitta",
      "Thiruvananthapuram",
      "Thrissur",
      "Wayanad",
    ],
  },
  {
    state: "Lakshadweep (UT)",
    statevalue: "lakshadweep_(UT)",
    districts: [
      "Agatti",
      "Amini",
      "Androth",
      "Bithra",
      "Chethlath",
      "Kavaratti",
      "Kadmath",
      "Kalpeni",
      "Kilthan",
      "Minicoy",
    ],
  },
  {
    state: "Madhya Pradesh",
    statevalue: "madhya_pradesh",
    districts: [
      "Agar Malwa",
      "Alirajpur",
      "Anuppur",
      "Ashoknagar",
      "Balaghat",
      "Barwani",
      "Betul",
      "Bhind",
      "Bhopal",
      "Burhanpur",
      "Chhatarpur",
      "Chhindwara",
      "Damoh",
      "Datia",
      "Dewas",
      "Dhar",
      "Dindori",
      "Guna",
      "Gwalior",
      "Harda",
      "Hoshangabad",
      "Indore",
      "Jabalpur",
      "Jhabua",
      "Katni",
      "Khandwa",
      "Khargone",
      "Mandla",
      "Mandsaur",
      "Morena",
      "Narsinghpur",
      "Neemuch",
      "Panna",
      "Raisen",
      "Rajgarh",
      "Ratlam",
      "Rewa",
      "Sagar",
      "Satna",
      "Sehore",
      "Seoni",
      "Shahdol",
      "Shajapur",
      "Sheopur",
      "Shivpuri",
      "Sidhi",
      "Singrauli",
      "Tikamgarh",
      "Ujjain",
      "Umaria",
      "Vidisha",
    ],
  },
  {
    state: "Maharashtra",
    statevalue: "maharashtra",
    districts: [
      "Ahmednagar",
      "Akola",
      "Amravati",
      "Aurangabad",
      "Beed",
      "Bhandara",
      "Buldhana",
      "Chandrapur",
      "Dhule",
      "Gadchiroli",
      "Gondia",
      "Hingoli",
      "Jalgaon",
      "Jalna",
      "Kolhapur",
      "Latur",
      "Mumbai City",
      "Mumbai Suburban",
      "Nagpur",
      "Nanded",
      "Nandurbar",
      "Nashik",
      "Osmanabad",
      "Palghar",
      "Parbhani",
      "Pune",
      "Raigad",
      "Ratnagiri",
      "Sangli",
      "Satara",
      "Sindhudurg",
      "Solapur",
      "Thane",
      "Wardha",
      "Washim",
      "Yavatmal",
    ],
  },
  {
    state: "Manipur",
    statevalue: "manipur",
    districts: [
      "Bishnupur",
      "Chandel",
      "Churachandpur",
      "Imphal East",
      "Imphal West",
      "Jiribam",
      "Kakching",
      "Kamjong",
      "Kangpokpi",
      "Noney",
      "Pherzawl",
      "Senapati",
      "Tamenglong",
      "Tengnoupal",
      "Thoubal",
      "Ukhrul",
    ],
  },
  {
    state: "Meghalaya",
    statevalue: "meghalaya",
    districts: [
      "East Garo Hills",
      "East Jaintia Hills",
      "East Khasi Hills",
      "North Garo Hills",
      "Ri Bhoi",
      "South Garo Hills",
      "South West Garo Hills ",
      "South West Khasi Hills",
      "West Garo Hills",
      "West Jaintia Hills",
      "West Khasi Hills",
    ],
  },
  {
    state: "Mizoram",
    statevalue: "mizoram",
    districts: [
      "Aizawl",
      "Champhai",
      "Kolasib",
      "Lawngtlai",
      "Lunglei",
      "Mamit",
      "Saiha",
      "Serchhip",
    ],
  },
  {
    state: "Nagaland",
    statevalue: "nagaland",
    districts: [
      "Dimapur",
      "Kiphire",
      "Kohima",
      "Longleng",
      "Mokokchung",
      "Mon",
      "Peren",
      "Phek",
      "Tuensang",
      "Wokha",
      "Zunheboto",
    ],
  },
  {
    state: "Odisha",
    statevalue: "odisha",
    districts: [
      "Angul",
      "Balangir",
      "Balasore",
      "Bargarh",
      "Bhadrak",
      "Boudh",
      "Cuttack",
      "Deogarh",
      "Dhenkanal",
      "Gajapati",
      "Ganjam",
      "Jagatsinghapur",
      "Jajpur",
      "Jharsuguda",
      "Kalahandi",
      "Kandhamal",
      "Kendrapara",
      "Kendujhar (Keonjhar)",
      "Khordha",
      "Koraput",
      "Malkangiri",
      "Mayurbhanj",
      "Nabarangpur",
      "Nayagarh",
      "Nuapada",
      "Puri",
      "Rayagada",
      "Sambalpur",
      "Sonepur",
      "Sundargarh",
    ],
  },
  {
    state: "Puducherry (UT)",
    statevalue: "puducherry_(UT)",
    districts: ["Karaikal", "Mahe", "Pondicherry", "Yanam"],
  },
  {
    state: "Punjab",
    statevalue: "punjab",
    districts: [
      "Amritsar",
      "Barnala",
      "Bathinda",
      "Faridkot",
      "Fatehgarh Sahib",
      "Fazilka",
      "Ferozepur",
      "Gurdaspur",
      "Hoshiarpur",
      "Jalandhar",
      "Kapurthala",
      "Ludhiana",
      "Mansa",
      "Moga",
      "Muktsar",
      "Nawanshahr (Shahid Bhagat Singh Nagar)",
      "Pathankot",
      "Patiala",
      "Rupnagar",
      "Sahibzada Ajit Singh Nagar (Mohali)",
      "Sangrur",
      "Tarn Taran",
    ],
  },
  {
    state: "Rajasthan",
    statevalue: "rajasthan",
    districts: [
      "Ajmer",
      "Alwar",
      "Banswara",
      "Baran",
      "Barmer",
      "Bharatpur",
      "Bhilwara",
      "Bikaner",
      "Bundi",
      "Chittorgarh",
      "Churu",
      "Dausa",
      "Dholpur",
      "Dungarpur",
      "Hanumangarh",
      "Jaipur",
      "Jaisalmer",
      "Jalore",
      "Jhalawar",
      "Jhunjhunu",
      "Jodhpur",
      "Karauli",
      "Kota",
      "Nagaur",
      "Pali",
      "Pratapgarh",
      "Rajsamand",
      "Sawai Madhopur",
      "Sikar",
      "Sirohi",
      "Sri Ganganagar",
      "Tonk",
      "Udaipur",
    ],
  },
  {
    state: "Sikkim",
    statevalue: "sikkim",
    districts: ["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"],
  },
  {
    state: "Tamil Nadu",
    statevalue: "tamil_nadu",
    districts: [
      "Ariyalur",
      "Chennai",
      "Coimbatore",
      "Cuddalore",
      "Dharmapuri",
      "Dindigul",
      "Erode",
      "Kanchipuram",
      "Kanyakumari",
      "Karur",
      "Krishnagiri",
      "Madurai",
      "Nagapattinam",
      "Namakkal",
      "Nilgiris",
      "Perambalur",
      "Pudukkottai",
      "Ramanathapuram",
      "Salem",
      "Sivaganga",
      "Thanjavur",
      "Theni",
      "Thoothukudi (Tuticorin)",
      "Tiruchirappalli",
      "Tirunelveli",
      "Tiruppur",
      "Tiruvallur",
      "Tiruvannamalai",
      "Tiruvarur",
      "Vellore",
      "Viluppuram",
      "Virudhunagar",
    ],
  },
  {
    state: "Telangana",
    statevalue: "telangana",
    districts: [
      "Adilabad",
      "Bhadradri Kothagudem",
      "Hyderabad",
      "Jagtial",
      "Jangaon",
      "Jayashankar Bhoopalpally",
      "Jogulamba Gadwal",
      "Kamareddy",
      "Karimnagar",
      "Khammam",
      "Komaram Bheem Asifabad",
      "Mahabubabad",
      "Mahabubnagar",
      "Mancherial",
      "Medak",
      "Medchal",
      "Nagarkurnool",
      "Nalgonda",
      "Nirmal",
      "Nizamabad",
      "Peddapalli",
      "Rajanna Sircilla",
      "Rangareddy",
      "Sangareddy",
      "Siddipet",
      "Suryapet",
      "Vikarabad",
      "Wanaparthy",
      "Warangal (Rural)",
      "Warangal (Urban)",
      "Yadadri Bhuvanagiri",
    ],
  },
  {
    state: "Tripura",
    statevalue: "tripura",
    districts: [
      "Dhalai",
      "Gomati",
      "Khowai",
      "North Tripura",
      "Sepahijala",
      "South Tripura",
      "Unakoti",
      "West Tripura",
    ],
  },
  {
    state: "Uttarakhand",
    statevalue: "uttarakhand",
    districts: [
      "Almora",
      "Bageshwar",
      "Chamoli",
      "Champawat",
      "Dehradun",
      "Haridwar",
      "Nainital",
      "Pauri Garhwal",
      "Pithoragarh",
      "Rudraprayag",
      "Tehri Garhwal",
      "Udham Singh Nagar",
      "Uttarkashi",
    ],
  },
  {
    state: "Uttar Pradesh",
    statevalue: "uttar_pradesh",
    districts: [
      "Agra",
      "Aligarh",
      "Allahabad",
      "Ambedkar Nagar",
      "Amethi (Chatrapati Sahuji Mahraj Nagar)",
      "Amroha (J.P. Nagar)",
      "Auraiya",
      "Azamgarh",
      "Baghpat",
      "Bahraich",
      "Ballia",
      "Balrampur",
      "Banda",
      "Barabanki",
      "Bareilly",
      "Basti",
      "Bhadohi",
      "Bijnor",
      "Budaun",
      "Bulandshahr",
      "Chandauli",
      "Chitrakoot",
      "Deoria",
      "Etah",
      "Etawah",
      "Faizabad",
      "Farrukhabad",
      "Fatehpur",
      "Firozabad",
      "Gautam Buddha Nagar",
      "Ghaziabad",
      "Ghazipur",
      "Gonda",
      "Gorakhpur",
      "Hamirpur",
      "Hapur (Panchsheel Nagar)",
      "Hardoi",
      "Hathras",
      "Jalaun",
      "Jaunpur",
      "Jhansi",
      "Kannauj",
      "Kanpur Dehat",
      "Kanpur Nagar",
      "Kanshiram Nagar (Kasganj)",
      "Kaushambi",
      "Kushinagar (Padrauna)",
      "Lakhimpur - Kheri",
      "Lalitpur",
      "Lucknow",
      "Maharajganj",
      "Mahoba",
      "Mainpuri",
      "Mathura",
      "Mau",
      "Meerut",
      "Mirzapur",
      "Moradabad",
      "Muzaffarnagar",
      "Pilibhit",
      "Pratapgarh",
      "RaeBareli",
      "Rampur",
      "Saharanpur",
      "Sambhal (Bhim Nagar)",
      "Sant Kabir Nagar",
      "Shahjahanpur",
      "Shamali (Prabuddh Nagar)",
      "Shravasti",
      "Siddharth Nagar",
      "Sitapur",
      "Sonbhadra",
      "Sultanpur",
      "Unnao",
      "Varanasi",
    ],
  },
  {
    state: "West Bengal",
    statevalue: "west_bengal",
    districts: [
      "Alipurduar",
      "Bankura",
      "Birbhum",
      "Burdwan (Bardhaman)",
      "Cooch Behar",
      "Dakshin Dinajpur (South Dinajpur)",
      "Darjeeling",
      "Hooghly",
      "Howrah",
      "Jalpaiguri",
      "Kalimpong",
      "Kolkata",
      "Malda",
      "Murshidabad",
      "Nadia",
      "North 24 Parganas",
      "Paschim Medinipur (West Medinipur)",
      "Purba Medinipur (East Medinipur)",
      "Purulia",
      "South 24 Parganas",
      "Uttar Dinajpur (North Dinajpur)",
    ],
  },
];
