// Stations

export const Stations = [
    {
      Option: "All Station",
      optionvalue: "All Station",
    },
    {
      Option: "Ahmedabad",
      optionvalue: "Ahmedabad",
    },
    {
      Option: "Bangalore",
      optionvalue: "Bangalore",
    },
    {
      Option: "Chennai",
      optionvalue: "Chennai",
    },
    {
      Option: "Coimbatore",
      optionvalue: "Coimbatore",
    },
    {
      Option: "Delhi",
      optionvalue: "Delhi",
    },
    {
      Option: "ERODE",
      optionvalue: "ERODE",
    },
    {
      Option: "GOA",
      optionvalue: "GOA",
    },
    {
      Option: "Hyderabad",
      optionvalue: "Hyderabad",
    },
    {
      Option: "INDORE",
      optionvalue: "INDORE",
    },
    {
      Option: "Kerala",
      optionvalue: "Kerala",
    },
    {
      Option: "Kolkata",
      optionvalue: "Kolkata",
    },
    {
      Option: "Madurai",
      optionvalue: "Madurai",
    },
    {
      Option: "Mangalore",
      optionvalue: "Mangalore",
    },
    {
      Option: "Mumbai",
      optionvalue: "Mumbai",
    },
    {
      Option: "Pune",
      optionvalue: "Pune",
    },
    {
      Option: "Tiruchi",
      optionvalue: "Tiruchi",
    },
    {
      Option: "Vijayawada",
      optionvalue: "Vijayawada",
    },
  ];

  export const RateTypevendor = [
    {
      Option: "Customer",
      optionvalue: "Customer",
    },
    {
      Option: "Supplier",
      optionvalue: "Supplier",
    },
  ];