import React from 'react';
import "./EtripSheetMap.css"

const EtripSheetMap = () => {
  return (
    <>
      <div>
        <p>Map</p>

        <div className='EtripSheetMap-card'>

        </div>
      </div>
    </>
  )
}

export default EtripSheetMap;
