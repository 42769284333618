import React from 'react';
import "./EtripSheetSignature.css"

 const EtripSheetSignature = () => {
  return (
    <>
    <div>
      <p>Signature</p>
    
    <div className='EtripSheetSignature-card'>

    </div>
    </div>
    </>
  )
}
export default EtripSheetSignature;

